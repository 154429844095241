<template>
  <div class="wrapper" @click="handleClick">
    <section class="sanction-date">
      <p>{{ trial.sanctionBody }}</p>
      <p>{{ getDate(trial.startDate) }} - {{ getDate(trial.endDate) }}</p>
    </section>
    <section class="event-type" @click="classRegistration">
      <div>
        <p>{{ trial.eventType }}</p>
      </div>
    </section>
    <section class="trial-location" @click="classRegistration">
      <p>{{ trial.trialName }}</p>
      <p>{{ trial.city }}, {{ trial.state }}</p>
    </section>
    <section class="dog-classes">
      <p class="number-classes">{{ numberOfClasses }}</p>
      <p>Classes {{ runType }}</p>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import { Trial } from "@/models/trialModel";

export default {
  props: {
    trialType: String,
    trial: Object,
    numberOfClasses: Number,
  },
  computed: {
    runType() {
      return this.trialType === "upcomingTrials" ? "Entered" : "Completed";
    },
  },
  methods: {
    getDate(theDate) {
      return moment(theDate).format("MMM D");
    },
    async handleClick() {
      await this.$store.dispatch(
        "dog/setDogActiveTrial",
        new Trial(this.trial)
      );
      this.$router.push({
        name: "dogClassList",
        params: { trialType: this.trialType, trialId: this.trial._id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  max-width: 1110px;

  &:hover {
    cursor: pointer;
  }
}

section {
  padding: 6px 10px;
  margin-right: 5px;
  background: #fff;
  border: 1px solid $tertiary-25;

  p {
    padding: 0;
    margin: 3px 0;
    color: $tertiary;
  }
}

.sanction-date {
  width: 13%;
  border-left: 10px solid $primary !important;
  border-radius: 5px 0 0 5px;
}

.event-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 24%;
}

.dog-classes {
  width: 15%;
  text-align: center;

  .number-classes {
    font-size: 1.0625rem;
    font-weight: bold;
    color: $primary;
  }
}

.trial-location {
  width: 19%;
  text-align: center;
}
</style>
