<template>
  <div class="dog-top-actions">
    <el-tooltip effect="dark" content="Add Trial" placement="left">
      <font-awesome-icon
        :icon="['fa', 'plus-circle']"
        @Click="handleAddTrial"
      ></font-awesome-icon>
    </el-tooltip>
  </div>

  <DogTrialList
    v-for="trial in dogTrials"
    :key="trial._id"
    :trialType="trialType"
    :trial="trial"
    :numberOfClasses="getNumberClasses(trial._id)"
  />
</template>

//
<script>
import DogTrialList from "../components/dog/DogTrialList.vue";

export default {
  props: { trialType: String },
  components: { DogTrialList },
  computed: {
    dogTrials() {
      return this.trialType === "upcomingTrials"
        ? this.$store.getters["dog/getDogUpcomingTrials"]
        : this.$store.getters["dog/getDogPastTrials"];
    },
  },
  methods: {
    getNumberClasses(trialIdToGet) {
      const trialFromDog = this.$store.getters["dog/getDog"].trials.find(
        (trial) => trial.trialId === trialIdToGet
      );
      return trialFromDog.classRegistrations.length;
    },
    handleAddTrial() {
      this.$router.push({ name: "addtrial" });
    },
  },
};
</script>

<style lang="scss" scoped>
.dog-top-actions {
  margin-bottom: 15px;
}
</style>
